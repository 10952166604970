@import '~app_schuurman/variables';
@import '~app_schuurman/global';

// Keq Kids colors
$color-keq-pelorous: #39b2c5;
$color-keq-cerulean: #00b1cc;
$color-keq-clementine: #f07500;
$color-keq-jewel: #16652d;
$color-keq-christi: #66a415;
$color-keq-bahia: #93ce11;

.page[data-action=Page-KeqKidsLanding] {
    .site-footer {
        padding-top: 0;
    }
}

.keq-kids-landing {
    .nowrap {
        white-space: nowrap;
    }

    .page-title,
    .sub-title,
    .benefit-title,
    .benefit-more-info,
    a.btn,
    p {
        font-family: 'CorporativeSans';
    }

    .page-title,
    .sub-title {
        font-size: 44px;
        line-height: 50px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 60px;
            line-height: 68px;
            padding: 65px 0 25px;
        }
    }

    .sub-title {
        color: $styleguide-white;
        margin-bottom: 25px;
    }

    p {
        color: $styleguide-white;
        font-size: 18px;
        line-height: 26px;
        max-width: 980px;
        padding: 0 20px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 24px;
            line-height: 36px;
        }
    }

    a.btn {
        background-color: $styleguide-white;
        border-radius: 40px;
        color: $color-keq-clementine;
        font-size: 18px;
        font-weight: bold;
        min-width: 200px ;

        &:hover {
            background-color: $styleguide-black;
            color: $styleguide-white;
        }

        &.secondary {
            background-color: $color-keq-clementine;
            color: $styleguide-white;

            &:hover {
                background-color: $styleguide-black;
                color: $styleguide-white;
            }
        }
    }
}

.keq-kids__header {
    background: var(--keqHeaderBgMobile) no-repeat center bottom,
                linear-gradient(0deg, rgba($color-keq-pelorous, 0.15) 0%, rgba($color-keq-cerulean,0.15) 100%);
    background-size: contain;
    padding-top: 40px;
    position: relative;

    @include media-breakpoint-up(md) {
        background: var(--keqHeaderBg) no-repeat center bottom,
                    linear-gradient(0deg, rgba($color-keq-pelorous, 0.15) 0%, rgba($color-keq-cerulean,0.15) 100%);
        background-size: contain;
        padding-top: 100px;
    }

    .keq-kids__header-clouds {
        background: var(--keqHeaderCloudsMobile) no-repeat scroll center 40px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            background: var(--keqHeaderClouds) no-repeat scroll center 40px;
        }
    }

    .container {
        padding-bottom: 73.21782178217822%;
        position: relative;

        @include media-breakpoint-up(md) {
            background: var(--keqHeaderBug) no-repeat 75% 10%;
            padding-bottom: 45%;
        }

        @include media-breakpoint-up(lg) {
            background-position: 70% 10%;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 35%;
        }
    }

    .keq-logo {
        width: 160px;

        @include media-breakpoint-up(md) {
            width: 240px;
        }
    }

    .page-title {
        color: $color-keq-pelorous;
        padding: 45px 50px 35px;

        @include media-breakpoint-up(lg) {
            padding: 65px 0 25px;
        }

        &::before {
            display: none;
        }
    }

    p {
        color: $styleguide-grey-900;
    }
}

.keq-kids__nav {
    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 50px 0 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-top: 60px;
        }
    }

    li {
        + li {
            margin-top: 20px;

            @include media-breakpoint-up(md) {
                margin: 0 0 0 20px;
            }
        }
    }
}

.keq-kids__benefits {
    background-color: $color-keq-christi;
    margin-top: -1px; // To prevent a 0.5px line at the bottom of the background image being visible due to scaling
    padding-bottom: 50px;

    .container {
        @include media-breakpoint-up(lg) {
            transform: translateY(-100px);
        }
    }

    .sub-title {
        padding-bottom: 35px;

        @include media-breakpoint-up(md) {
            font-size: 52px;
            padding-bottom: 25px;
        }
    }

    p {
        font-size: 18px;
        line-height: 26px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.keq-kids__benefits-grid {
    max-width: 1090px;

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(3, auto);
        gap: 80px 35px;
        margin-top: 100px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(2, auto);
    }

    .benefit {
        border: 2px solid $color-keq-bahia;
        border-radius: 20px;
        transition: background-color .25s ease-in-out,
                    border-color .25s ease-in-out;

        @include media-breakpoint-up(md) {
            border-radius: 30px;
        }

        &:hover {
            background-color: $color-keq-jewel;
            border-color: $color-keq-jewel;

            .benefit-more-info {
                color: $color-keq-bahia;
            }
        }

        + .benefit {
            margin-top: 10px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }

        button {
            align-items: center;
            background-color: transparent;
            border: none;
            display: flex;
            height: 100%;
            justify-content: space-between;
            padding: 15px;
            text-decoration: none;
            width: 100%;

            @include media-breakpoint-up(md) {
                flex-direction: column;
                text-align: center;
            }
        }
    }

    .benefit-img {
        flex: 0 0 120px;
        margin: 0;
        width: 120px;

        @include media-breakpoint-up(md) {
            flex-basis: auto;
            margin-top: -60px;
            width: 225px;
        }

        img {
            width: 100%;
        }
    }

    .benefit-title {
        color: $styleguide-white;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        flex: 0 1 calc(100% - 150px);

        @include media-breakpoint-up(md) {
            flex: 1 1 100%;
            font-size: 24px;
            line-height: 30px;
            margin-top: 35px;
            max-width: 280px;
        }
    }

    .benefit-more-info {
        color: $styleguide-white;
        display: none;
        font-size: 20px;
        font-weight: bold;
        line-height: 29px;
        padding: 30px 0 35px;
        text-transform: uppercase;
        transition: color .25s ease-in-out;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
}

.keq-kids__footer {
    background: $color-keq-jewel var(--keqFooterBgMobile) no-repeat center top;
    background-size: contain;
    padding-top: 40px;

    @include media-breakpoint-up(md) {
        background-image: var(--keqFooterBg);
        background-size: contain;
        padding-top: 100px;
    }

    >  .container {
        padding-top: 140px;

        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }
    }

    .sub-title {
        max-width: 500px;
    }

    p {
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    a.btn {
        background-color: $color-keq-clementine;
        color: $styleguide-white;
        margin-top: 50px;

        @include media-breakpoint-up(lg) {
            margin-top: 60px;
        }

        &:hover {
            background-color: $styleguide-white;
            color: $color-keq-clementine;
        }
    }
}

.keq-kids__footer-ground {
    background-color: $color-keq-jewel;
    max-height: 370px;
    padding-top: 70px;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
    }

    .ground {
        margin-top: -100px;
        position: relative;
        z-index: 0;
    }
}

.keq-kids__footer-characters {
    position: relative;
    z-index: 1;

    .shoes {
        transform: translateY(-35px);
        width: 40%;

        @include media-breakpoint-up(md) {
            transform: translateY(-45px);
            width: 30%;
        }
    }

    .caterpillar {
        margin: 0 10% 0 20%;
        width: 10%;

        @include media-breakpoint-up(md) {
            transform: translateY(50px);
            width: 8%;
        }
    }
}

.modal {
    &.keq-kids-landing {
        &.show {
            &::before {
                background: rgba($styleguide-grey-900, .5);
                bottom: 0;
                content: '';
                left: 0;
                min-height: 100vh;
                min-width: 100vw;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 20;
            }

            .modal-dialog {
                border-radius: 30px;
                box-shadow: none;
                left: 0;
                margin: 0 auto;
                max-width: 800px;
                overflow: visible;
                right: 0;
                width: 90vw;

                @include media-breakpoint-up(md) {
                    left: 50%;
                    right: auto;
                }
            }

            .modal-content {
                display: flex;
                flex-direction: column;
                max-height: 80vh;
                padding: 20px 35px 30px 35px;

                @include media-breakpoint-up(md) {
                    padding: 60px 60px 90px 60px;
                }
            }

            .modal-header {
                justify-content: end;
                margin: 0 0 10px;
                padding: 0;
                width: 100%;
            }

            .modal-body {
                overflow-y: auto;
                padding: 0 15px;

                p {
                    padding: 0;
                }
            }
        }

        .close-btn {
            align-items: center;
            background-color: $color-keq-clementine;
            border: none;
            border-radius: 60px;
            color: $styleguide-white;
            display: flex;
            flex: 0 0 60px;
            font-size: 40px;
            height: 60px;
            justify-content: center;
            margin-top: -50px;
            padding: 0;
            width: 60px;

            @include media-breakpoint-up(md) {
                margin-top: -90px;
            }

            &:hover {
                background-color: $styleguide-black;
            }
        }

        .modal-title {
            color: $color-keq-clementine;
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 25px;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 32px;
                line-height: 34px;
                margin-bottom: 30px;
            }
        }

        p {
            color: $styleguide-grey-900;
            font-size: 16px;
            line-height: 24px;

            @include media-breakpoint-up(md) {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}
